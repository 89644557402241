import ArtistDropdown from 'components/Dropdown/ArtistDropdown';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import LiveDropdown from 'components/Dropdown/LiveDropdown';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import Tile from 'components/Tile/Tile';
import { get, sortedUniqBy } from 'lodash-es';
import { getTrackUrl } from 'utils/url';
import {
  MFSProps,
  MFSStation,
  MFSTrack,
} from 'components/MyFavoriteSongs/types';
import { STATION_TYPE, StationTypeValue } from 'constants/stationTypes';
import { TILE_RES } from 'components/MediaServerImage';

// AA - 3/23/20 - IHRWEB-14832
// moved outside of component, add trackingContext to arguments
const getTrackInfo = (
  trackingContext: string,
  { stationId, seedType, trackId }: MFSTrack,
  allTracks: MFSProps['allTracks'],
): MFSStation | null => {
  const track: MFSTrack = get(allTracks, String(trackId));

  if (track) {
    return {
      artistId: track.artistId,
      description: track.artistName,
      id: stationId,
      imgUrl: track.imageUrl || '',
      imgWidth: 240,
      key: `dropdown|${track.url || track.imageUrl}`,
      name: track.title || '',
      seedId: trackId,
      seedType,
      tileType: 'track',
      trackId,
      trackingContext,
      url:
        getTrackUrl(
          track.artistId,
          track.artistName,
          trackId,
          track.title || '',
        ) || '',
    };
  }
  return null;
};
// AA - 3/23/20 - IHRWEB-14832
// moved outside of component, add playedFrom to arguments
const getTile =
  ({
    playedFrom,
    singleRow,
    tilesInRow,
  }: {
    playedFrom: number;
    singleRow?: boolean;
    tilesInRow: number;
  }) =>
  // eslint-disable-next-line react/display-name
  (station: MFSStation) => {
    const {
      artistId,
      description,
      imgUrl,
      imgWidth,
      key,
      name,
      seedId,
      seedType,
      tileType,
      trackId,
      url: tileUrl,
      // AA - 3/23/20 - IHRWEB-14832
      // always set station type to track to allow AA users to start radio with track
    } = {
      ...station,
      seedType: STATION_TYPE.TRACK,
    };
    const PlayButton = PlayerStateProxy(PlayButtonContainer);
    const playButton = (
      <PlayButtonContainerPrimitive>
        <PlayButton
          artistId={artistId}
          artistName={description}
          className="play"
          deferPlay={!!tileUrl}
          playedFrom={playedFrom}
          // AA - 3/23/20 - IHRWEB-14832 - fix mismatched seed id and station type. Always track station, use seedId (trackId)
          stationId={seedId}
          stationType={seedType}
          trackId={trackId}
          trackName={name}
        />
      </PlayButtonContainerPrimitive>
    );

    return (
      // AA - 3/23/20 - IHRWEB-14832
      // remove unused props from  Tile
      <Tile
        dropdown={
          STATION_TYPE.ARTIST ?
            <ArtistDropdown
              artist={station}
              followed
              key={`artist-${seedId}`}
            />
          : <LiveDropdown
              key={`station-${seedId}`}
              name={name}
              seedId={seedId as string}
              seedType={seedType}
            />
        }
        key={key}
        singleRow={singleRow}
        tilesInRow={tilesInRow}
        title={name}
        url={tileUrl}
      >
        <NavLink to={tileUrl}>
          {playButton}
          <CatalogImage
            alt={name}
            aspectRatio={1}
            height={imgWidth}
            id={seedType}
            src={imgUrl}
            type={tileType as StationTypeValue}
            width={TILE_RES}
          />
        </NavLink>
      </Tile>
    );
  };

// AA - 3/23/20 - IHRWEB-14832
// moved outside of component
// reduce, sort, and map tracks to tile contents
const reduceTracks =
  ({
    allTracks,
    trackingContext,
  }: Pick<MFSProps, 'allTracks' | 'trackingContext'>) =>
  (mappedTracks: Array<MFSStation>, track: MFSTrack): Array<MFSStation> => {
    // if a track is returned, add it
    const trackInfo = getTrackInfo(trackingContext, track, allTracks);

    if (trackInfo) {
      mappedTracks.push(trackInfo);
    }

    return mappedTracks;
  };

const sortTracks = (a: MFSStation, b: MFSStation): number => {
  // sort by track name and then artist name
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  const aArtist = a.description.toLowerCase();
  const bArtist = b.description.toLowerCase();
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  if (aArtist < bArtist) return -1;
  return 1;
};

export const getTilesContent = ({
  allTracks,
  favoriteTracks,
  playedFrom,
  singleRow,
  tilesInRow,
  trackingContext,
}: Pick<
  MFSProps,
  | 'allTracks'
  | 'favoriteTracks'
  | 'playedFrom'
  | 'singleRow'
  | 'tilesInRow'
  | 'trackingContext'
>) => {
  const tracks =
    singleRow ? favoriteTracks.slice(0, tilesInRow) : favoriteTracks;
  const mappedAndSorted = tracks
    .reduce(reduceTracks({ allTracks, trackingContext }), [])
    .sort(sortTracks);
  // remove dups then map to tiles
  const deduped = sortedUniqBy(mappedAndSorted, track => track.trackId);
  return deduped.map(getTile({ playedFrom, singleRow, tilesInRow }));
};
