import MyFavoriteSongs from './MyFavoriteSongs';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCurrentFavoritedTracks,
  getFavoritedTrackIdsNotLoaded,
} from 'state/Favorites/selectors';
import { getListenHistoryReceived } from 'state/Stations/selectors';
import { getProfileId } from 'state/Session/selectors';
import { getTracks } from 'state/Tracks/selectors';
import { requestTracks } from 'state/Tracks/actions';

const mapStateToProps = createStructuredSelector({
  activeUserProfileId: getProfileId,
  allTracks: getTracks,
  favoritedTrackIdsNotLoaded: getFavoritedTrackIdsNotLoaded,
  favoriteTracks: getCurrentFavoritedTracks,
  listenHistoryReceived: getListenHistoryReceived,
});

export default connect(mapStateToProps, { requestTracks })(MyFavoriteSongs);
