import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Helmet } from 'react-helmet';
import { translateKeywords } from 'utils/metaUtils';

export type Props = {
  currentPath: string;
};

function Head() {
  const translate = useTranslate();

  const title = translate('Your Favorite Songs | Log In & See');
  const description = translate(
    'Log in or sign up and find the songs you love here, only on iHeart.',
  );
  const keywords = translateKeywords(
    translate,
    [
      'iHeartRadio',
      'iHeart',
      'Radio',
      'Internet',
      'Music',
      'Talk',
      'Listen',
      'Live',
      'Artist',
      'Song',
      'Playlist',
      'On Demand',
      'Discover',
      'Personalized',
      'Free',
      'App',
      'Online',
    ].join(','),
  );

  return (
    <>
      <GenericHead
        description={description}
        metaDescription={description}
        noAltLink
        ogType="music.playlist"
        title={title}
      />
      <Helmet>
        <meta content={keywords} name="keywords" />
      </Helmet>
    </>
  );
}

export default Head;
