import Section from 'components/Section';
import Tiles from 'components/Tiles/Tiles';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getTilesContent } from 'components/MyFavoriteSongs/helpers';
import { MFSProps } from 'components/MyFavoriteSongs/types';
import { useEffect, useRef } from 'react';

const MyFavoriteSongs = ({
  allTracks,
  favoriteTracks,
  favoritedTrackIdsNotLoaded,
  listenHistoryReceived,
  playedFrom,
  requestTracks,
  singleRow,
  tilesInRow,
  trackingContext,
  url,
}: MFSProps) => {
  const translate = useTranslate();

  let { current: favoritedTrackIdsNotLoadedRef } = useRef(
    favoritedTrackIdsNotLoaded,
  );

  useMount((): void => {
    requestTracks(favoritedTrackIdsNotLoadedRef);
  });

  useEffect(() => {
    if (listenHistoryReceived && favoritedTrackIdsNotLoaded.length !== 0) {
      favoritedTrackIdsNotLoadedRef = favoritedTrackIdsNotLoaded;
      requestTracks(favoritedTrackIdsNotLoadedRef);
    }
  }, [listenHistoryReceived, favoritedTrackIdsNotLoadedRef]);

  const tiles =
    Object.values(allTracks).length ?
      <Tiles tilesInRow={tilesInRow}>
        {getTilesContent({
          allTracks,
          favoriteTracks,
          playedFrom,
          singleRow,
          tilesInRow,
          trackingContext,
        })}
      </Tiles>
    : null;
  return (
    <Section
      dataTest="my-profile-favoritesongs"
      emptyMessage={translate("You haven't thumbed up any songs yet.")}
      header={translate('Favorite Songs')}
      isEmpty={!tiles}
      url={url}
    >
      {tiles}
    </Section>
  );
};
export default MyFavoriteSongs;
