import FavoriteSongs from 'components/MyFavoriteSongs';
import Head from './Head';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import useMount from 'hooks/useMount';

export type Props = {
  profileId: number | null;
  setHasHero: (hasHero: boolean) => void;
};

export default function Songs({ profileId, setHasHero }: Props) {
  useMount(() => {
    setHasHero(false);
  });

  return (
    <>
      <Head />
      <PageBody dataTest={ViewName.ProfileSongs}>
        <FavoriteSongs
          playedFrom={PLAYED_FROM.PROF_USER_FAVORITE_SONGS}
          profileId={profileId}
          tilesInRow={3}
          trackingContext="my:favorite-songs"
        />
      </PageBody>
    </>
  );
}
